<template>
  <Page full>
    <Grid
      type="tax"
      title="Taxes"
      :multiple="true"
      :permanent-filters="{
        company_id: $store.state.session.company.company_id
      }"
      :visible="['tax_name', 'tax_desc', 'tax_percentage']"
      :isMain="true"
    />
  </Page>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  name: 'TaxesPage',
  components: {
    Grid
  }
}
</script>
